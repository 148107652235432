exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-relaxation-index-js": () => import("./../../../src/pages/relaxation/index.js" /* webpackChunkName: "component---src-pages-relaxation-index-js" */),
  "component---src-pages-relaxation-intro-js": () => import("./../../../src/pages/relaxation/intro.js" /* webpackChunkName: "component---src-pages-relaxation-intro-js" */),
  "component---src-pages-relaxation-protocol-js": () => import("./../../../src/pages/relaxation-protocol.js" /* webpackChunkName: "component---src-pages-relaxation-protocol-js" */)
}

